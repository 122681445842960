import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'domSanitizer'
})
export class DomSanitizerPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer){}

  transform(imgUrl: string) {
    const safeImgUrl = this.domSanitizer.bypassSecurityTrustStyle(`background: url(${imgUrl}); background-position: center; background-size: cover; background-repeat: no-repeat;`);
    return  safeImgUrl
  }

}
