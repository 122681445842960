import { Injectable } from '@angular/core';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { environment } from 'src/environments/environment';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  private _token: string = '';
  public message: any = null;

  get token() {
    return this._token;
  }

  set token(value) {
    this._token = value;
  }

  constructor(
    private _platform: Platform,
    private _notificationSv: NotificationsService
  ) {}

  initPush() {
    if (this._platform.is('capacitor')) {
      this.pushMobile();
    } else {
      this.pushWeb();
    }
  }

  pushWeb() {
    this.requestPermission();
    this.listen();
  }

  pushMobile() {
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        PushNotifications.register();
      }
    });
    PushNotifications.addListener('registration', (token: Token) => {
      this.token = token.value || '';
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        await this.showNotificationAlert();
      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        this.doAction(notification.notification);
      }
    );
  }

  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: environment.firebaseConfig.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          this.token = currentToken;
        } else {
          console.error(
            'No registration token available. Request permission to generate one.'
          );
        }
      })
      .catch((err) => {
        console.error('An error occurred while retrieving token. ', err);
      });
  }

  doAction(notification: any) {
    this._notificationSv.goToNotification(notification);
  }

  async showNotificationAlert() {
    await this._notificationSv.totalNotifications();
    this._notificationSv.showNotification.emit();
    this.soundNotification();
  }

  soundNotification() {
    const context = new AudioContext();
    const T = context.currentTime;

    const osc1 = context.createOscillator();
    osc1.type = 'square';
    osc1.frequency.value = 800;

    const osc2 = context.createOscillator();
    osc2.type = 'square';
    osc2.frequency.value = 540;

    const gain = context.createGain();
    osc1.connect(gain);
    osc2.connect(gain);
    gain.gain.setValueAtTime(0.5, T);
    gain.gain.exponentialRampToValueAtTime(0.01, T + 1.0);

    const filter = context.createBiquadFilter();
    filter.type = 'bandpass';
    filter.frequency.value = 800;
    gain.connect(filter);

    filter.connect(context.destination);

    osc1.start(T);
    osc2.start(T);
    osc1.stop(T + 1.1);
    osc2.stop(T + 1.1);
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      this.showNotificationAlert();
    });
  }
}
