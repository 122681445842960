import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class CarruselService {
  constructor(private navCtrl: NavController) {}

  async checkStatus() {
    const isNewUser = localStorage.getItem('carrousel');
    if (!isNewUser) {
      const redirectPath = `${window.location.pathname}${window.location.search}`;
      await this.navCtrl.navigateRoot('carrousel', {
        queryParams: {
          redirectPath: redirectPath.includes('/carrousel')
            ? 'home'
            : redirectPath,
        },
      });
    }
  }
}
