import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';

interface ICurrency {
  change: number;
  name: string;
  symbol: string;
  type: string;
}

@Injectable({
  providedIn: 'root',
})
export class BalanceService {
  public currencySource = new EventEmitter<ICurrency>();
  private _domainId = environment.domainId;
  public currency: ICurrency = undefined;

  constructor(private httpSv: HttpService) {
    this.getCurrencyStorage();
  }

  getCurrencyStorage() {
    const currency = localStorage.getItem('currency');
    if (!this.currency && currency && currency != 'undefined') {
      this.currency = JSON.parse(currency);
      this.currency.change = Number(this.currency.change);
    }

    this.currencySource.emit(this.currency);
  }

  async getCurrencies() {
    const res: any = await this.httpSv.itemIndex(
      'currency',
      `${this._domainId}/1`
    );
    return res.data;
  }
}
