import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { LayoutModule } from '@angular/cdk/layout';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es-AR';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat/';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { HttpClient } from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateStore,
} from '@ngx-translate/core';
import { MenuSsrComponent } from './components/menu-ssr/menu-ssr.component';

//Plugins

import { Storage } from '@ionic/storage-angular';
import { Stripe } from '@awesome-cordova-plugins/stripe/ngx';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

//PIPES;

import { PipeModule } from './pipes/pipe-module.module';

import { CustomTranslateLoader } from './customTranslateLoader/CustomTranslateLoader';
// import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient): CustomTranslateLoader {
  const module = new CustomTranslateLoader(http);
  return module;
}

//FIREBASE

import { initializeApp } from 'firebase/app';
import { NgxPayPalModule } from 'ngx-paypal';
initializeApp(environment.firebaseConfig);

registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [AppComponent, MenuSsrComponent],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    IonicModule.forRoot({
      _forceStatusbarPadding: true,
    }),
    AppRoutingModule,
    LayoutModule,
    PipeModule,
    FormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    NgxPayPalModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production && environment.PWA,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Storage,
    { provide: LOCALE_ID, useValue: 'es' },
    Stripe,
    GooglePlus,
    SignInWithApple,
    TranslateStore,
    Meta,
    SocialSharing,
    InAppBrowser,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
